.ag-theme-custom.ag-theme-alpine .ag-header-cell,
.ag-theme-custom.ag-theme-alpine .ag-header-group-cell {
    padding-left: 8px;
    padding-right: 8px;
}

.ag-theme-custom.ag-theme-alpine .red-cell {
    background-color: #ffc7ce;
    color: #9c0006;
}

.ag-theme-custom.ag-theme-alpine .yellow-cell {
    background: #ffbd56;
    color: #ac5c00;
}
.ag-theme-custom.ag-theme-alpine .green-cell {
    background-color: #c6efce;
    color: #006100;
}
.ag-theme-custom.ag-theme-alpine .grey-cell {
    background-color: #d8d8d8;
}
.ag-theme-custom.ag-theme-alpine .link-cell {
    text-decoration: underline;
    cursor: pointer;
    color: #33a7eb;
}
.ag-theme-custom.ag-theme-alpine .ag-row {
    font-size: 12px;
}
